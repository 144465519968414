<template>
  <img
      :src="baseInfo.avatar"
      alt="头像"
      class="avaUrl"
  />
</template>

<script setup>
import {useAttrs, computed} from "vue";
import {useStore} from "vuex";

const store = useStore()
const baseInfo = computed(() => store.state.baseic)
const attrs = useAttrs()
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "UserPic",
});
</script>

<style lang="scss" scoped>
.avaUrl {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 50% !important;
}
</style>
