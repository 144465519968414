<template>
  <el-dialog
      v-model="modelValue"
      width="480"
      modal-class="shop-dialog"
      :before-close="handleClose"
      title="分享邀请好友"
      align-center
  >
    <div class="share-content">
      <!--      <div class="share-sub-title">被邀请人购买满一年，邀请人与被邀请人均<span class="bule">可额外获赠一个月</span></div>-->
      <div class="share-box ">
        <div class="text">通过链接邀请</div>
        <div class="link dp_f">
          <div class="l">{{ url }}</div>
          <div class="r">邀请码：{{ baseInfo.mcode }}</div>
        </div>
        <div class="btn-box mt_32">
          <button type="button" class="wy-button primary" @click="handleCopy">复制链接及邀请码</button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, toRefs} from "vue";
import {useStore} from "vuex";
import {copyText} from "@utils/tool";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
})
const emit = defineEmits(['update:modelValue'])
const {modelValue} = toRefs(props)
const {proxy} = getCurrentInstance();
const url = window.location.host
const baseInfo = computed(() => store.state.baseic)
const store = useStore()
const handleCopy = () => {
  const str = url + '/register?inviteCode=' + baseInfo.value.mcode
  copyText(str)
}
const handleClose = () => {
  emit('update:modelValue', false)
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shareModal",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
