import request from '../http'
import {managerUrl} from '../api'

//导航菜单
export function getMenuList(data){
  return request({
    url:managerUrl + "/carton/menu/info/list",
    method:'post',
    data
  })
}
//收藏菜单列表
export function getNavLikeList(data){
  return request({
    url:managerUrl + "/carton/menu/info/like/list",
    method:'post',
    data
  })
}

//二级菜单收藏列表
export function getMenuLikeList(data){
  return request({
    url:managerUrl + "/carton/menu/info/like/list",
    method:'post',
    data
  })
}

//二级菜单收藏接口
export function getMenuLike(data){
  return request({
    url:managerUrl + "/carton/menu/info/like",
    method:'post',
    data
  })
}

//二级菜单取消收藏接口
export function getMenuDislike(data){
  return request({
    url:managerUrl + "/carton/menu/info/dislike",
    method:'post',
    data
  })
}

//首页收藏列表排序
export function menuSort(data){
  return request({
    url:managerUrl + "/carton/menu/info/like/sort",
    method:'post',
    data
  })
}

//联系我们

