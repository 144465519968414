<template>
  <img alt="位元灵感"
       :src="imgUrl"
       :style="{ width: `${props.height}px`, height: `${props.width}px` }"
  />
</template>

<script setup>
import {defineProps, useAttrs} from 'vue'

const imgUrl = require('@img/index/logoNew.png')
const attrs = useAttrs()
const props = defineProps({
  width: {
    type: Number,
    default: 28
  },
  height: {
    type: Number,
    default: 76
  },
})
</script>

<style lang="scss" scoped>
</style>