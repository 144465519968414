<template>
  <div class="userInfo right dp_f" :class="where">
    <el-popover
        placement="bottom"
        transition="el-zoom-in-top"
        :width="160"
        trigger="click"
        popper-class="headPoper"
    >
      <template #reference>
        <div class="userData dp_f cur_p">
          <user-pic/>
          <div class="user">
            <div class="user_name">{{ userInfo.userName }}</div>
            <div class="company_name" v-if='!baseInfo.outTime'>
              {{ userInfo.companyName || "默认企业" }}
              <span class="buy-btn" @click="goBuy(1)">购买</span>
            </div>
            <div class="company_name" v-else>
              <member-time-str :time="baseInfo.outTime" class="member-str"></member-time-str>
              <span class="buy-btn" @click="goBuy(2)">续费</span>
            </div>
          </div>
        </div>
      </template>
      <div class="navList">
        <div class="single" @click="goUserCenter">
          <img src="@/assets/img/person/person.png" :style="{width:'12px',height:'12px'}" alt="位元灵感"/>
          <span>个人中心</span>
        </div>
        <div class="single" @click="goBuy(3)">
          <img src="@/assets/img/person/buy.png" :style="{width:'12px',height:'12px'}" alt="位元灵感"/>
          <span>采购耗材</span>
        </div>
        <div class="single" @click="showShare = true">
          <img src="@/assets/img/person/share.png" :style="{width:'12px',height:'12px'}" alt="位元灵感"/>
          <span>分享有礼</span>
        </div>
        <div class="single" @click="handleLogOut">
          <img src="@/assets/img/person/logout.png" :style="{width:'12px',height:'12px'}" alt="位元灵感"/>
          <span>退出登录</span>
        </div>
      </div>
    </el-popover>
    <slot></slot>
    <share-modal v-model="showShare"></share-modal>
  </div>
</template>

<script setup>
import UserPic from "@components/common/userPic/index.vue";
import {computed, getCurrentInstance, onMounted, ref, toRefs} from "vue";
import {ElMessageBox} from "element-plus";
import {loginOut} from "@utils/api/user";
import {removeToken} from "@utils/tool/token";
import {useRouter} from "vue-router";
import {setBaseUserInfo} from "@utils/tool/getUserInfo";
import ShareModal from "@components/shareModal/index.vue";
import {useStore} from "vuex";
import MemberTimeStr from "@views/shop/components/shopUser/time.vue";

onMounted(()=>{
  setBaseUserInfo()
})
const store = useStore()
const baseInfo = computed(() => store.state.baseic)
const {proxy} = getCurrentInstance();
const router = useRouter();
const userInfo = computed(() => proxy.$cookies.get("userInfo") || {});
const showShare = ref(false)
const props = defineProps({
  where: {
    type: String,
    default: 'index', // index 首页顶部，2，layout 详情页顶部
  }
})
const {where} = toRefs(props)
const goUserCenter = () => {
  router.push({
    path: "/personal/center",
  });
};
const goBuy = (type) => {
  let api = type === 1 ? "/shop/order/buy/1" : type === 2 ? "/shop/order/renewal/2" : "/shop/goodsList";
  router.push({
    path:api,
  });
};
const handleLogOut = () => {
  ElMessageBox.confirm("是否确认退出登录", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    loginOut().then(() => {
      proxy.$message.success("退出成功");
      proxy.$cookies.remove("userInfo");
      removeToken()
      router.push({
        path: "/login",
      });
    });
  });
};

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "UserInfoTop",
});
</script>

<style lang="scss">
.headPoper {
  border-radius: 16px !important;
  padding: 0 0 !important;

  .navList {
    border-radius: 16px !important;
    overflow: hidden;
    padding: 8px 0;

    .single {
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;

      &:hover {
        color: var(--el-color-background);
        transition: 0.5s all linear;
        font-weight: 600;
      }

      > span {
        margin-left: 10px;
        user-select: none;
      }
    }
  }
}

.userInfo {
  flex-wrap: nowrap;
  &.index {
    .user_name {
      color: #fff;
    }
    .company_name {
      color: #fff;
    }
  }

  &.layout {
    .user_name {
      color: $fsColor4;
    }

    .company_name {
      color: $fsColor4;
      .member-str{
          font-size: 12px;
          .time{
            color: $fsColor4 !important;
            font-weight: normal;
          }
          .desc{
            color: $fsColor4 !important;
            font-weight: normal;
          }
          .over-time{
            color: $fsColor4 !important;
          }
        }
      .buy-btn {
        display: inline-block;
        margin-left: 10px;
        width: 40px;
        height: 20px;
        border: 1px solid  $fsColor4 !important;
        border-radius: 8px;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        color: $fsColor4 !important;
      }
    }
  }

  .userData {
    margin-right: 32px;
    .avaUrl {
      border-radius: 8px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      margin-right: 10px;
    }

    .user {
      position: relative;
        top: 2px;
      .user_name {
        font-size: 12px;
        line-height: 1;
      }
      .company_name {
        margin-top: 4px;
        font-size: 12px;
        opacity: 0.5;
        line-height: 1;
        display: flex;
        align-items: center;
        .member-str{
          font-size: 12px;
          .time{
            color: #fff;
            font-weight: normal;
          }
          .desc{
            color: #fff;
            font-weight: normal;
          }
          .over-time{
            color: #fff;
          }
        }
        .buy-btn {
          display: inline-block;
          margin-left: 10px;
          width: 40px;
          height: 20px;
          border: 1px solid #FFFFFF;
          border-radius: 8px;
          font-size: 10px;
          line-height: 18px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}


</style>
