import {computed, onMounted, ref} from 'vue';
import message from "@utils/tool/message";
import {colorConfig} from "@/components/config";
import {getMenuList} from "@/utils/api/system";
import route from "@/router";

//共用一个menu
const menu = ref([]);
const menuChecked = computed(() => {
    const fullPath = route.currentRoute.value.meta?.routeName || route.currentRoute.value.fullPath.split('?')[0];
    let checked = {};
    let flag = false;
    for (let i = 0; i < menu.value.length; i++) {
        let item = menu.value[i];
        if (item.childList) {
            for (let j = 0; j < item.childList.length; j++) {
                let child = item.childList[j];
                if (child.jumpUrl === fullPath) {
                    checked = child
                    flag = true
                    break
                }
            }
            if (flag) {
                break
            }
        }
    }
    return checked
});
const childrenChecked = computed(() => {
    return menu.value.find(item => item.menuId === menuChecked.value.fatherMenuId)?.childList
})
const getMenu = async () => {
    try {
        const res = await getMenuList();
        if (res.code === 0) {
            menu.value = setMenu(res.data.list);
        } else {
            message.error(res.message);
        }
    } catch (e) {
        message.error(e.message);
    }
}
const setMenu = (list = []) => {
    return list.map(item => {
        if (item.childList) {
            item.childList.forEach(child => {
                child.fatherMenuId = item.menuId
            })
        }
        item.bg = colorConfig[item.menuId];
        return item
    })
}
export default function useMenu(callBack) {
    //重置menu
    onMounted(async () => {
        await getMenu()
        if (typeof callBack === 'function') {
            callBack(menu.value)
        }
    })
    return {
        menu,
        getMenu,
        menuChecked,
        childrenChecked
    }
}