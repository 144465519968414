//cookie
import VueCookies from 'vue-cookies'
import {baseUserInfo} from '@utils/api/user.js'
import {useStore} from "vuex";
import {setUserInfo} from '@utils/tool/token.js'
import moment from "moment";

export function getUserInfo() {
    return VueCookies.get('userInfo')
}

export async function setBaseUserInfo(cb) {
    const store = useStore()
       const res = await baseUserInfo()
        if (res.code !== 0) return
        if (res.data.outTime) {
            res.data.outTime = moment(res.data.outTime).format('YYYY-MM-DD')
        } else {
            res.data.outTime = ''
        }
        store.dispatch('baseic', res.data)
        setUserInfo(res.data)
        if (cb) cb()

}